﻿/**
 *  @ngdoc controller
 *  @name Drilldot Directive
 *  @description Drill-Dot Directive
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('rxBillingDetailPanel', function ($timeout) {
        return {
            controller: 'rxBillingDetailController',
            restrict: 'E',
            scope: {
                modelId: "@",
                ctrl: '='
            },
            templateUrl: 'app/directive/rxBillingDetail/rxBillingDetaildir.html'
        }
    }).controller('rxBillingDetailController', function ($scope, eyeResource) {

    });

}(window.angular));